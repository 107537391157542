import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { FileUploadListComponent } from '../../common/file/file-upload-list/file-upload-list.component';
import { SocialTextareaComponent } from '../social-textarea/social-textarea.component';
import { SocialContentCardComponent } from '../social-content-card/social-content-card.component';
import { FileEntity } from '../../../common/models/files/file-entity';
import { SocialAttachment } from '../../../common/models/social-network/social-attachment';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { ModalService } from '../../../services/modal.service';
import { SocialContentModalComponent } from '../social-content-modal/social-content-modal.component';
import { SearchResult } from '../../../common/models/common/search/search-result';
import { TranslateModule } from '@ngx-translate/core';
import { FILES_CONSTANTS_BY_TYPE } from '../../../common/constants/file.constants';
import { SocialRepostBodyComponent } from '../social-repost-body/social-repost-body.component';
import { CommonService } from '../../../services/common.service';
import { faLink, faImages, faFileAlt } from '@fortawesome/free-solid-svg-icons';
import { FileTypes } from '@meplis/common';

@Component({
  selector: 'mec-social-publication-create-body',
  imports: [
    CommonModule,
    FileUploadListComponent,
    SocialTextareaComponent,
    SocialContentCardComponent,
    FontAwesomeModule,
    TranslateModule,
    SocialRepostBodyComponent,
  ],
  templateUrl: './social-publication-create-body.component.html',
  styleUrls: ['./social-publication-create-body.component.scss'],
})
export class SocialPublicationCreateBodyComponent {
  @Input() public socialThread: SocialNetWorkThread;
  @Input() public socialNetWorkId: string;
  @Input() public isCommentMode: boolean;
  @Input() public socialThreadToRepost: SocialNetWorkThread;

  @ViewChild(SocialTextareaComponent)
  public socialTextareaComponent: SocialTextareaComponent;

  @ViewChild(FileUploadListComponent)
  public fileUploadListComponent: FileUploadListComponent;

  public linkIcon = faLink;
  public imagesIcon = faImages;
  public fileIcon = faFileAlt;

  public filesEvent: Event;

  public documentAcceptTypes: string;
  public imageOrVideoAcceptTypes: string;

  public isMobile: boolean;

  constructor(
    private _modalService: ModalService,
    private _commonService: CommonService
  ) {
    this.isMobile = this._commonService.isMobile;

    this.imageOrVideoAcceptTypes = CommonHelper.filterAcceptTypes([
      FileTypes.IMAGE_OR_VIDEO,
    ]);
    this.documentAcceptTypes = CommonHelper.filterAcceptTypes([
      FileTypes.DOCUMENT,
    ]);
  }

  public clearInput(): void {
    this.fileUploadListComponent.clearFiles();
    this.socialTextareaComponent.bodyText = undefined;
  }

  public publicationBodyChange(text: string): void {
    this.socialThread.body = text;
  }

  public removeFile(fileSelectedId: number): void {
    this.socialThread.attachments = this.socialThread.attachments.filter(
      (file) => file.id !== fileSelectedId
    );
  }

  public confirmFile(libraryFile: FileEntity): void {
    const attachment: SocialAttachment = {
      ...libraryFile,
    };

    if (!this.socialThread.attachments) {
      this.socialThread.attachments = [];
    }

    this.socialThread.attachments.push(attachment);
  }

  public removeContent(): void {
    this.socialThread.states = [];
  }

  public async openAppContentModal(socialNetworkId: string): Promise<void> {
    const modalResult = await this._modalService.openModal<
      SocialContentModalComponent,
      string
    >(SocialContentModalComponent, socialNetworkId);

    modalResult.afterClosed().subscribe((modalSearchResult: SearchResult) => {
      if (modalSearchResult) {
        this.socialThread?.states.push({
          id: modalSearchResult.id,
          type: modalSearchResult.ownContentType,
          content: modalSearchResult,
        });
      }
    });
  }

  public loadFileFromDevice($event: any): void {
    this.filesEvent = $event;
  }
}
