import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SearchResult } from '../../../common/models/common/search/search-result';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { AppConstants } from '../../../common/constants/app.constants';
import { TranslateModule } from '@ngx-translate/core';
import { ArticleType } from '../../../common/enums/article/article-type';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-social-content-card',
  imports: [CommonModule, FontAwesomeModule, TranslateModule],
  templateUrl: './social-content-card.component.html',
  styleUrls: ['./social-content-card.component.scss'],
})
export class SocialContentCardComponent implements OnChanges {
  @Input() public searchItem: SearchResult;
  @Input() public isEditable: boolean;

  @Output() public removeContentChange = new EventEmitter<void>();

  public typeIcon: IconProp;
  public typeLabel: string;
  public imageUrl: string;

  public removeIcon = faTrash;

  constructor(private _commonService: CommonService) {}

  public ngOnChanges(): void {
    if (this.searchItem) {
      if (this.searchItem.content.description) {
        this.searchItem.content.description =
          CommonHelper.removeHtmlTagsFromString(
            this.searchItem?.content?.description
          );
      }

      const pageType = AppConstants.pageTypes.find(
        (type) => type.type === this.searchItem.type
      );
      this.typeIcon = this._commonService.getIcon(pageType.icon);

      this.typeLabel = pageType.label;

      let imageHash = this.searchItem?.defaultImageHash;
      if (this.searchItem.coverFileHash) {
        imageHash = this.searchItem.coverFileHash;
      }

      if (this.searchItem?.defaultImageHash) {
        this.imageUrl = CommonHelper.getUrlFile(
          this.searchItem?.defaultImageHash
        );
      }
    }
  }

  public goToContent(): void {
    switch (this.searchItem.type) {
      case ArticleType.EXAM ||
        ArticleType.CONTENT ||
        ArticleType.MULTIPLE_CHOICE ||
        ArticleType.SURVEY ||
        ArticleType.CERTIFICATE:
        this.goToCourseStep();
        break;
      case ArticleType.EVENTS:
        this.goToEvent();
        break;
      case ArticleType.NEWS:
        this.goToNews();
        break;
      case ArticleType.FILE:
        this.goToFile();
        break;
      case ArticleType.FOLDER:
        this.goToFolder();
        break;
    }
  }

  public goToFile(): void {
    const extras = {
      queryParams: {
        courseId: this.searchItem?.fileId,
      },
    };

    this._commonService.navigate([Routes['library'].fullPath], extras);
  }

  public goToFolder(): void {
    this._commonService.navigate([
      Routes['libraryFolder'].fullPath,
      this.searchItem?.folder?.slug,
    ]);
  }

  public goToNews(): void {
    this._commonService.navigate([
      Routes['newsDetail'].fullPath,
      this.searchItem.slug,
    ]);
  }

  public goToEvent(): void {
    this._commonService.navigate([
      Routes['event'].fullPath,
      this.searchItem.slug,
    ]);
  }

  public goToCourseStep(): void {
    const classRoomRoute = Routes['classroom'];
    let routeWithSlug = classRoomRoute.fullPath.replace(
      ':courseSlug',
      this.searchItem.course.slug
    );
    routeWithSlug = routeWithSlug.replace(
      ':stepId',
      this.searchItem.steps[0].id.toString()
    );

    const routeArray = routeWithSlug.split('/');

    const extras = {
      queryParams: {
        courseId: this.searchItem?.course.id,
      },
    };

    this._commonService.navigate(routeArray, extras);
  }

  public clickRemoveContent(): void {
    this.removeContentChange.emit();
  }
}
