import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ToastService } from '../../../services/toast.service';
import { ApiChainService } from '../../../services/api/api-chain.service';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import { ProgramService } from '../../../services/program.service';
import { DynamicHtmlModule } from '../../common/dynamic-html/dynamic-html.module';

@Component({
  selector: 'mec-program-output',
  imports: [CommonModule, DynamicHtmlModule],
  templateUrl: './program-output.component.html',
  styleUrls: ['./program-output.component.scss'],
})
export class ProgramOutputComponent implements OnInit {
  @Input() public programNode: ProgramNode;
  @Input() public logs: ProgramLog[];
  @Input() public programToken: string;

  @Input() public dependecies: string[];

  public isDependency: boolean;

  constructor(
    private _toastService: ToastService,
    private _apiChainService: ApiChainService,
    private _programService: ProgramService
  ) {
    this._programService.onStepDependecyChange.subscribe(
      async (dependencyId: string) => {
        if (
          `${this.programNode.project}::${dependencyId}` === this.programNode.id
        ) {
          this.isDependency = true;
          const logsWithoutOwn = this.logs.filter(
            (log) => log.to && log.to !== this.programNode.id
          );
          await this._executeSentece(logsWithoutOwn);
        }
      }
    );
  }

  public ngOnInit(): void {
    this._checkIsDependecy();
  }

  private _checkIsDependecy(): void {
    this.isDependency = this.dependecies.some(
      (dependencyId) =>
        `${this.programNode.project}::${dependencyId}` === this.programNode.id
    );
  }

  private async _executeSentece(logs: ProgramLog[]): Promise<void> {
    try {
      // const result = await this._apiChainService.executeSentence(
      //   this.programToken,
      //   this.programNode,
      //   logs
      // );
      // if (result) {
      //   this.programNode.messageValue = result;
      // }
    } catch (error) {
      this._toastService.showError(error);
    }
  }
}
