import { Language } from '../../../enums/language/language';

export class LanguageFilter {
  public name: string;
  public language: Language;
  public countryName?: string;
  public isSelected?: boolean;
  public isDisabled?: boolean;
  public isGlobal?: boolean;
  public flagCode: string;
}
