import { CommonConstant } from '../constants/common.constants';
import {
  IconName,
  IconPrefix,
  IconProp,
} from '@fortawesome/fontawesome-svg-core';
import { environment } from '../../environments/environment';
import { FileParams } from '../models/files/file-params';
import { FILES_CONSTANTS_BY_TYPE } from '../constants/file.constants';
import { FileTypes } from '../enums/file/file-types';

export class CommonHelper {
  public static extractFileIdentifier(input: string): string {
    const output = input.match(/\/(\d+|[a-z0-9]{64})\/view/);
    return output[1];
  }

  public static extractFileUrl(input: string): string {
    const output = input.split('/view');
    return output[0];
  }

  public static isFileHash(input: string): boolean {
    return !!input.match(/^[a-f0-9]{64}$/gi);
  }

  public static getFileUrl(
    idOrHash: number | string,
    params?: FileParams
  ): string {
    if (!params) {
      // mleon(): defaults to image url getter
      params = {
        format: CommonConstant.imageFormat,
        width: 352,
      };
    }

    const host = idOrHash.toString().match(/^[a-f0-9]{64}$/gi) // mleon(): hexadecimal hash with 64 chars
      ? environment.filesV3
      : environment.filesV2;
    const output = new URL(`${host}/${idOrHash}/view`);
    const paramsKeys = Object.keys(params);
    const paramsValues = Object.values(params);

    let lenght = paramsKeys.length;
    while (lenght--) {
      output.searchParams.append(
        paramsKeys[lenght],
        paramsValues[lenght].toString()
      );
    }

    return output.toString();
  }

  public static getUrlFile(
    fileHash: string,
    aditionalFormatOptions?: string,
    accessToken?: string,
    imageFormat?: string
  ): string {
    let token = '';
    if (accessToken) {
      token = `&access_token=${accessToken}`;
    }

    let fileFormatValue = '';

    if (imageFormat) {
      fileFormatValue = imageFormat;
    }

    return `${environment.filesV3}/${fileHash}/view?${fileFormatValue}${aditionalFormatOptions}${token}`;
  }

  public static getUrlFileById(
    fileId: number,
    aditionalFormatOptions?: string,
    accessToken?: string,
    imageFormat?: string
  ): string {
    if (!aditionalFormatOptions) {
      aditionalFormatOptions = '&width=352';
    }

    let token = '';
    if (accessToken) {
      token = `&access_token=${accessToken}`;
    }

    let fileFormatValue = '';
    if (aditionalFormatOptions.indexOf('format') === -1) {
      fileFormatValue = CommonConstant.imageFormat;
      if (imageFormat) {
        fileFormatValue = imageFormat;
      }
    }

    return `${environment.filesV2}/${fileId}/view?${fileFormatValue}${aditionalFormatOptions}${token}`;
  }

  public static getAvatarImage(id: number | string): string {
    return `${environment.apiUrl}profile/v2/${id}/avatar?&height=100&smart=true&width=100`;
  }

  public static getHeaderTimezone(): string {
    return Intl.DateTimeFormat().resolvedOptions().timeZone || 'UTC';
  }

  public static getUrlByState(state: string): string {
    // rfarias(): this method is used to resolve old angular routes using state
    const stateArray = state.split('.');
    return stateArray[1];
  }

  public static getIcon(
    value: string,
    iconPrefixValue: IconPrefix = 'fas'
  ): IconProp {
    const valueArray = value.split('-');

    let iconPrefix = valueArray[0] as IconPrefix;
    valueArray.shift();
    const iconName = valueArray.join('-') as IconName;

    if (iconPrefixValue) {
      iconPrefix = iconPrefixValue;
    }

    return [iconPrefix, iconName] as unknown as [IconPrefix, IconName];
  }

  public static removeHtmlTagsFromString(value: string): string {
    return value.replace(/(<([^>]+)>)/gi, '');
  }

  public static orderByPropertyValue<T>(array: T[], key: string): T[] {
    return array.sort((left: any, right: any) => {
      if (left[key] > right[key]) {
        return 1;
      }

      if (left[key] < right[key]) {
        return -1;
      }

      return 0;
    });
  }

  public static orderIfExistsProperty<T>(array: T[], key: string): T[] {
    return array.sort((left: any, right: any) => {
      return left[key] ? -1 : right[key] ? 1 : 0;
    });
  }

  public static groupByProperty<T>(array: T[], key: string): any {
    return array.reduce((result: any, value: any) => {
      (result[value[key]] = result[value[key]] || []).push(value);
      return result;
    }, {});
  }

  public static filterAcceptTypes(constantTypes: Array<FileTypes>): string {
    let mimeTypesString = '';

    constantTypes.forEach((constantType) => {
      const typesExtensions = FILES_CONSTANTS_BY_TYPE[constantType]
        .extensions as unknown as any;
      const mimeTypesArray = Object.keys(typesExtensions).map((key) => {
        return typesExtensions[key].mimeType.join(',');
      });
      mimeTypesString += mimeTypesArray.join(',');
    });

    return mimeTypesString;
  }
}
