<mec-modal
  [options]="modalOptions"
  (positiveClick)="saveLibraryItem()"
  [disableActions]="isSaving || !isEditableMode"
  (closeClick)="matDialogRef.close(false)"
>
  <form [formGroup]="editFileForm" class="edit-modal">
    <div class="edit-modal__content-img">
      <fa-icon
        [icon]="documentIcon"
        class="edit-modal__icon"
        *ngIf="hiddenImage"
      ></fa-icon>

      <img
        *ngIf="libraryFileInfo?.url"
        [ngClass]="{ hidden: isLoadingImage || hiddenImage }"
        (error)="hiddenImage = true; isLoadingImage = false"
        (load)="isLoadingImage = false"
        class="edit-modal__img"
        src="{{ libraryFileInfo.url + '?' + imageFormat + imageDefaultWidth }}"
      />

      <button
        *ngIf="!isLoadingImage"
        [disabled]="isDownloading"
        class="btn btn--sm btn--icon-text edit-modal__download-button"
        (click)="
          downloadImage(
            libraryFileInfo.url,
            libraryFileInfo.filename + '.' + libraryFileInfo.extension
          )
        "
      >
        <span *ngIf="!isDownloading">{{ 'DOWNLOAD' | translate }}</span>
        <span *ngIf="isDownloading">{{ 'DOWNLOADING' | translate }}</span>
        <fa-icon
          *ngIf="!isDownloading"
          class="edit-modal__download-icon"
          [icon]="downloadIcon"
        ></fa-icon>
        <mat-spinner *ngIf="isDownloading"></mat-spinner>
      </button>

      <!-- Skeleton loader start -->
      <div
        [medSkeletonLoading]="true"
        *ngIf="isLoadingImage"
        style="height: 250px"
      ></div>
      <!-- Skeleton loader end -->
    </div>

    <div class="edit-modal__container-info">
      <ng-container *ngIf="!isLoading">
        <div class="edit-modal__title-container">
          <h2 *ngIf="!editableMode">{{ libraryFileInfo.filename }}</h2>
          <input
            *ngIf="editableMode"
            class="field__input"
            formControlName="title"
            placeholder="E.g.: Img1234"
          />
          <button
            *ngIf="!editableMode && isEditableMode"
            class="btn btn--icon btn--no-bg"
            (click)="editMode()"
          >
            <fa-icon class="edit-modal__icon" [icon]="editIcon"></fa-icon>
          </button>
          <button
            *ngIf="editableMode"
            class="btn btn--icon btn--no-bg"
            (click)="editMode()"
          >
            <fa-icon class="edit-modal__icon" [icon]="readIcon"></fa-icon>
          </button>
        </div>
        <div>
          <div
            class="tag"
            color="light"
            *ngFor="let tag of libraryItem?.tags"
            slot="start"
          >
            {{ tag.name }}
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isLoading">
        <div class="edit-modal__features">
          <div class="edit-modal__feature">
            <div class="edit-modal__info-title-container">
              <fa-icon
                [icon]="infoIcon"
                class="edit-modal__info-icon"
              ></fa-icon>
              <span>{{ 'FEATURES.DESCRIPTION' | translate }}:</span>
            </div>
            <p [innerHtml]="libraryItem.content.description"></p>
          </div>

          <div class="edit-modal__feature">
            <div class="edit-modal__info-title-container">
              <fa-icon
                [icon]="documentIcon"
                class="edit-modal__info-icon"
              ></fa-icon>
              <span>{{ 'Extension' | translate }}:</span>
            </div>
            <p>{{ libraryFileInfo.extension }}</p>
          </div>

          <div class="edit-modal__feature">
            <div class="edit-modal__info-title-container">
              <fa-icon
                [icon]="databaseIcon"
                class="edit-modal__info-icon"
              ></fa-icon>
              <span>{{ 'Size' | translate }}:</span>
            </div>
            <p>{{ libraryFileInfo.length + ' kb' }}</p>
          </div>
        </div>
      </ng-container>

      <!-- Skeleton loader start -->
      <div
        [medSkeletonLoading]="true"
        *ngIf="isLoading"
        style="height: 80px"
        animated="true"
      ></div>
      <div
        [medSkeletonLoading]="true"
        *ngIf="isLoading"
        style="height: 120px"
        animated="true"
      ></div>
      <div
        [medSkeletonLoading]="true"
        *ngIf="isLoading"
        style="height: 80px"
        animated="true"
      ></div>
      <!-- Skeleton loader end -->
    </div>
  </form>
</mec-modal>
