export const FILE_MIME_TYPE: { [key: string]: string } = {
  'audio/x-mpeg': 'mpega',
  'application/postscript': 'ps',
  'audio/x-aiff': 'aiff',
  'application/x-aim': 'aim',
  'image/x-jg': 'art',
  'video/x-ms-asf': 'asx',
  'audio/basic': 'ulw',
  'video/x-msvideo': 'avi',
  'video/x-rad-screenplay': 'avx',
  'application/x-bcpio': 'bcpio',
  'application/octet-stream': 'exe',
  'image/bmp': 'dib',
  'text/html': 'html',
  'application/x-cdf': 'cdf',
  'application/pkix-cert': 'cer',
  'application/java': 'class',
  'application/x-cpio': 'cpio',
  'application/x-csh': 'csh',
  'text/css': 'css',
  'application/msword': 'doc',
  'application/xml-dtd': 'dtd',
  'video/x-dv': 'dv',
  'application/x-dvi': 'dvi',
  'application/vnd.ms-fontobject': 'eot',
  'text/x-setext': 'etx',
  'image/gif': 'gif',
  'application/x-gtar': 'gtar',
  'application/x-gzip': 'gz',
  'application/x-hdf': 'hdf',
  'application/mac-binhex40': 'hqx',
  'text/x-component': 'htc',
  'image/ief': 'ief',
  'text/vnd.sun.j2me.app-descriptor': 'jad',
  'application/java-archive': 'jar',
  'text/x-java-source': 'java',
  'application/x-java-jnlp-file': 'jnlp',
  'image/jpeg': 'jpg',
  'application/javascript': 'js',
  'text/plain': 'txt',
  'application/json': 'json',
  'audio/midi': 'midi',
  'application/x-latex': 'latex',
  'audio/x-mpegurl': 'm3u',
  'image/x-macpaint': 'pnt',
  'text/troff': 'tr',
  'application/mathml+xml': 'mathml',
  'application/x-mif': 'mif',
  'video/quicktime': 'qt',
  'video/x-sgi-movie': 'movie',
  'audio/mpeg': 'mpa',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpg',
  'video/mpeg2': 'mpv2',
  'application/x-wais-source': 'src',
  'application/x-netcdf': 'nc',
  'application/oda': 'oda',
  'application/vnd.oasis.opendocument.database': 'odb',
  'application/vnd.oasis.opendocument.chart': 'odc',
  'application/vnd.oasis.opendocument.formula': 'odf',
  'application/vnd.oasis.opendocument.graphics': 'odg',
  'application/vnd.oasis.opendocument.image': 'odi',
  'application/vnd.oasis.opendocument.text-master': 'odm',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.oasis.opendocument.graphics-template': 'otg',
  'application/vnd.oasis.opendocument.text-web': 'oth',
  'application/vnd.oasis.opendocument.presentation-template': 'otp',
  'application/vnd.oasis.opendocument.spreadsheet-template': 'ots',
  'application/vnd.oasis.opendocument.text-template': 'ott',
  'application/ogg': 'ogx',
  'video/ogg': 'ogv',
  'audio/ogg': 'spx',
  'application/x-font-opentype': 'otf',
  'audio/flac': 'flac',
  'application/annodex': 'anx',
  'audio/annodex': 'axa',
  'video/annodex': 'axv',
  'application/xspf+xml': 'xspf',
  'image/x-portable-bitmap': 'pbm',
  'image/pict': 'pict',
  'application/pdf': 'pdf',
  'image/x-portable-graymap': 'pgm',
  'audio/x-scpls': 'pls',
  'image/png': 'png',
  'image/x-portable-anymap': 'pnm',
  'image/x-portable-pixmap': 'ppm',
  'application/vnd.ms-powerpoint': 'pps',
  'image/vnd.adobe.photoshop': 'psd',
  'image/x-quicktime': 'qtif',
  'image/x-cmu-raster': 'ras',
  'application/rdf+xml': 'rdf',
  'image/x-rgb': 'rgb',
  'application/vnd.rn-realmedia': 'rm',
  'application/rtf': 'rtf',
  'text/richtext': 'rtx',
  'application/font-sfnt': 'sfnt',
  'application/x-sh': 'sh',
  'application/x-shar': 'shar',
  'application/x-stuffit': 'sit',
  'application/x-sv4cpio': 'sv4cpio',
  'application/x-sv4crc': 'sv4crc',
  'image/svg+xml': 'svgz',
  'application/x-shockwave-flash': 'swf',
  'application/x-tar': 'tar',
  'application/x-tcl': 'tcl',
  'application/x-tex': 'tex',
  'application/x-texinfo': 'texinfo',
  'image/tiff': 'tiff',
  'text/tab-separated-values': 'tsv',
  'application/x-font-ttf': 'ttf',
  'application/x-ustar': 'ustar',
  'application/voicexml+xml': 'vxml',
  'image/x-xbitmap': 'xbm',
  'application/xhtml+xml': 'xhtml',
  'application/vnd.ms-excel': 'xls',
  'application/xml': 'xsl',
  'image/x-xpixmap': 'xpm',
  'application/xslt+xml': 'xslt',
  'application/vnd.mozilla.xul+xml': 'xul',
  'image/x-xwindowdump': 'xwd',
  'application/vnd.visio': 'vsd',
  'audio/x-wav': 'wav',
  'image/vnd.wap.wbmp': 'wbmp',
  'text/vnd.wap.wml': 'wml',
  'application/vnd.wap.wmlc': 'wmlc',
  'text/vnd.wap.wmlsc': 'wmls',
  'application/vnd.wap.wmlscriptc': 'wmlscriptc',
  'video/x-ms-wmv': 'wmv',
  'application/font-woff': 'woff',
  'application/font-woff2': 'woff2',
  'model/vrml': 'wrl',
  'application/wspolicy+xml': 'wspolicy',
  'application/x-compress': 'z',
  'application/zip': 'zip',
};

export const FILES_CONSTANTS_BY_TYPE = {
  AUDIO: {
    extensions: {
      '.aac': {
        description: 'AAC audio file',
        extension: '.aac',
        mimeType: ['audio/aac'],
      },
      '.aif': {
        description: 'Audio Interchange File Format',
        extension: '.aif',
        mimeType: ['audio/aiff', 'audio/x-aiff'],
      },
      '.aiff': {
        description: 'Audio Interchange File Format',
        extension: '.aiff',
        mimeType: ['audio/aiff', 'audio/x-aiff'],
      },
      '.amr': {
        description: 'Adaptive Multi-Rate',
        extension: '.amr',
        mimeType: ['audio/amr', 'audio/3gpp', 'audio/3gpp2'],
      },
      '.asf': {
        description: 'Advanced Systems Format File',
        extension: '.asf',
        mimeType: ['audio/asf', 'audio/x-asf'],
      },
      '.iff': {
        description: 'Interchange File Format',
        extension: '.iff',
        mimeType: ['audio/iff', 'audio/x-iff'],
      },
      '.m3u': {
        description: 'Media Playlist File',
        extension: '.m3u',
        mimeType: ['audio/x-mpequrl'],
      },
      '.m4a': {
        description: 'MPEG-4 Audio File',
        extension: '.m4a',
        mimeType: ['audio/mpeg4', 'audio/x-mpeg4'],
      },
      '.mid': {
        description: 'Musical Instrument Digital Interface (MIDI)',
        extension: '.mid',
        mimeType: ['audio/midi'],
      },
      '.midi': {
        description: 'Musical Instrument Digital Interface (MIDI)',
        extension: '.midi',
        mimeType: ['audio/midi'],
      },
      '.mp3': {
        description: 'MP3 Audio File',
        extension: '.mp3',
        mimeType: ['audio/mpeg3', 'audio/x-mpeg3'],
      },
      '.mpa': {
        description: 'MPEG-2 Audio File',
        extension: '.mpa',
        mimeType: ['audio/mpeg'],
      },
      '.oga': {
        description: 'OGG audio',
        extension: '.oga',
        mimeType: ['audio/ogg'],
      },
      '.ogx': {
        description: 'OGG',
        extension: '.ogx',
        mimeType: ['application/ogg'],
      },
      '.wav': {
        description: 'Waveform Audio Format',
        extension: '.wav',
        mimeType: ['audio/x-wav'],
      },
      '.weba': {
        description: 'WEBM audio',
        extension: '.weba',
        mimeType: ['audio/webm'],
      },
      '.wma': {
        description: 'Windows Media Audio File',
        extension: '.wma',
        mimeType: ['audio/x-ms-wma'],
      },
      '.3gp': {
        description: '3GPP audio container',
        extension: '.3gp',
        mimeType: ['audio/3gpp'],
      },
      '.3g2': {
        description: '3GPP2 audio container',
        extension: '.3g2',
        mimeType: ['audio/3gpp2'],
      },
    },
    name: 'AUDIO',
    uti: ['public.audio'],
  },
  COMPRESSED: {
    extensions: {
      '.bz': {
        description: 'BZip archive',
        extension: '.bz',
        mimeType: ['application/x-bzip'],
      },
      '.bz2': {
        description: 'BZip2 archive',
        extension: '.bz2',
        mimeType: ['application/x-bzip2'],
      },
      '.rar': {
        description: 'RAR archive',
        extension: '.rar',
        mimeType: ['application/x-rar-compressed'],
      },
      '.tar': {
        description: 'Tape Archive (TAR)',
        extension: '.tar',
        mimeType: ['application/x-tar'],
      },
      '.zip': {
        description: 'ZIP archive',
        extension: '.zip',
        mimeType: ['application/zip'],
      },
      '.7z': {
        description: '7-zip archive',
        extension: '.7z',
        mimeType: ['application/x-7z-compressed'],
      },
    },
    name: 'COMPRESSED',
    uti: [
      'com.allume.stuffit-archive',
      'com.pkware.zip-archive',
      'org.gnu.gnu-tar-archive',
      'org.gnu.gnu-zip-archive',
      'org.gnu.gnu-zip-tar-archive',
      'public.tar-archive',
    ],
  },
  FONT: {
    extensions: {
      '.eot': {
        description: 'MS Embedded OpenType fonts',
        extension: '.eot',
        mimeType: ['application/vnd.ms-fontobject'],
      },
      '.otf': {
        description: 'OpenType font',
        extension: '.otf',
        mimeType: ['font/otf'],
      },
      '.ttf': {
        description: 'TrueType Font',
        extension: '.ttf',
        mimeType: ['font/ttf'],
      },
      '.woff': {
        description: 'Web Open Font Format (WOFF)',
        extension: '.woff',
        mimeType: ['font/woff'],
      },
      '.woff2': {
        description: 'Web Open Font Format (WOFF)',
        extension: '.woff2',
        mimeType: ['font/woff2'],
      },
    },
    name: 'FONT',
    uti: ['public.font'],
  },
  DOCUMENT: {
    extensions: {
      '.abw': {
        description: 'AbiWord document',
        extension: '.abw',
        mimeType: ['application/x-abiword'],
      },
      '.azw': {
        description: 'Amazon Kindle eBook format',
        extension: '.azw',
        mimeType: ['application/vnd.amazon.ebook'],
      },
      '.csv': {
        description: 'Comma-separated values (CSV)',
        extension: '.csv',
        mimeType: ['text/csv'],
      },
      '.doc': {
        description: 'Microsoft Word',
        extension: '.doc',
        mimeType: ['application/msword'],
      },
      '.docm': {
        description: 'Microsoft Word',
        extension: '.docm',
        mimeType: ['application/vnd.ms-word.document.macroEnabled.12'],
      },
      '.docx': {
        description: 'Microsoft Word',
        extension: '.docx',
        mimeType: [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
      },
      '.dot': {
        description: 'Microsoft Word',
        extension: '.dot',
        mimeType: ['application/msword'],
      },
      '.dotm': {
        description: 'Microsoft Word',
        extension: '.dotm',
        mimeType: ['application/vnd.ms-word.template.macroEnabled.12'],
      },
      '.dotx': {
        description: 'Microsoft Word',
        extension: '.dotx',
        mimeType: [
          'application/vnd.openxmlformats-officedocument.wordprocessingml.template',
        ],
      },
      '.epub': {
        description: 'Electronic publication (EPUB)',
        extension: '.epub',
        mimeType: ['application/epub+zip'],
      },
      '.mdb': {
        description: 'Microsoft Access',
        extension: '.mdb',
        mimeType: ['application/vnd.ms-access'],
      },
      '.odp': {
        description: 'OpenDocument presentation document',
        extension: '.odp',
        mimeType: ['application/vnd.oasis.opendocument.presentation'],
      },
      '.ods': {
        description: 'OpenDocument spreadsheet document',
        extension: '.ods',
        mimeType: ['application/vnd.oasis.opendocument.spreadsheet'],
      },
      '.odt': {
        description: 'OpenDocument text document',
        extension: '.odt',
        mimeType: ['application/vnd.oasis.opendocument.text'],
      },
      '.pdf': {
        description: 'Adobe Portable Document Format (PDF)',
        extension: '.pdf',
        mimeType: ['application/pdf'],
      },
      '.pot': {
        description: 'Microsoft PowerPoint',
        extension: '.pot',
        mimeType: ['application/vnd.ms-powerpoint'],
      },
      '.ppa': {
        description: 'Microsoft PowerPoint',
        extension: '.ppa',
        mimeType: ['application/vnd.ms-powerpoint'],
      },
      '.pps': {
        description: 'Microsoft PowerPoint',
        extension: '.pps',
        mimeType: ['application/vnd.ms-powerpoint'],
      },
      '.ppt': {
        description: 'Microsoft PowerPoint',
        extension: '.ppt',
        mimeType: ['application/vnd.ms-powerpoint'],
      },
      '.potm': {
        description: 'Microsoft PowerPoint',
        extension: '.potm',
        mimeType: ['application/vnd.ms-powerpoint.template.macroEnabled.12'],
      },
      '.potx': {
        description: 'Microsoft PowerPoint',
        extension: '.potx',
        mimeType: [
          'application/vnd.openxmlformats-officedocument.presentationml.template',
        ],
      },
      '.ppam': {
        description: 'Microsoft PowerPoint',
        extension: '.ppam',
        mimeType: ['application/vnd.ms-powerpoint.addin.macroEnabled.12'],
      },
      '.ppsm': {
        description: 'Microsoft PowerPoint',
        extension: '.ppsm',
        mimeType: ['application/vnd.ms-powerpoint.slideshow.macroEnabled.12'],
      },
      '.ppsx': {
        description: 'Microsoft PowerPoint',
        extension: '.ppsx',
        mimeType: [
          'application/vnd.openxmlformats-officedocument.presentationml.slideshow',
        ],
      },
      '.pptm': {
        description: 'Microsoft PowerPoint',
        extension: '.pptm',
        mimeType: [
          'application/vnd.ms-powerpoint.presentation.macroEnabled.12',
        ],
      },
      '.pptx': {
        description: 'Microsoft PowerPoint',
        extension: '.pptx',
        mimeType: [
          'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ],
      },
      '.rtf': {
        description: 'Rich Text Format (RTF)',
        extension: '.rtf',
        mimeType: ['application/rtf'],
      },
      '.txt': {
        description: 'Text, (generally ASCII or ISO 8859-n)',
        extension: '.txt',
        mimeType: ['text/plain'],
      },
      '.vsd': {
        description: 'Microsoft Visio',
        extension: '.vsd',
        mimeType: ['application/vnd.visio'],
      },
      '.xls': {
        description: 'Microsoft Excel',
        extension: '.xls',
        mimeType: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
      },
      '.xlt': {
        description: 'Microsoft Excel',
        extension: '.xlt',
        mimeType: ['application/vnd.ms-excel'],
      },
      '.xla': {
        description: 'Microsoft Excel',
        extension: '.xla',
        mimeType: ['application/vnd.ms-excel'],
      },
      '.xlsx': {
        description: 'Microsoft Excel',
        extension: '.xlsx',
        mimeType: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
      },
      '.xltx': {
        description: 'Microsoft Excel',
        extension: '.xltx',
        mimeType: [
          'application/vnd.openxmlformats-officedocument.spreadsheetml.template',
        ],
      },
      '.xlsm': {
        description: 'Microsoft Excel',
        extension: '.xlsm',
        mimeType: ['application/vnd.ms-excel.sheet.macroEnabled.12'],
      },
      '.xltm': {
        description: 'Microsoft Excel',
        extension: '.xltm',
        mimeType: ['application/vnd.ms-excel.template.macroEnabled.12'],
      },
      '.xlam': {
        description: 'Microsoft Excel',
        extension: '.xlam',
        mimeType: ['application/vnd.ms-excel.addin.macroEnabled.12'],
      },
      '.xlsb': {
        description: 'Microsoft Excel',
        extension: '.xlsb',
        mimeType: ['application/vnd.ms-excel.sheet.binary.macroEnabled.12'],
      },
    },
    name: 'DOCUMENT',
    uti: ['public.composite-​content', 'public.data', 'public.presentation'],
  },
  IMAGE: {
    extensions: {
      '.gif': {
        description: 'Graphics Interchange Format (GIF)',
        extension: '.gif',
        mimeType: ['image/gif'],
      },
      '.ico': {
        description: 'Icon format',
        extension: '.ico',
        mimeType: ['image/x-icon'],
      },
      '.jpeg': {
        description: 'JPEG images',
        extension: '.jpeg',
        mimeType: ['image/jpeg'],
      },
      '.jpg': {
        description: 'JPEG images',
        extension: '.jpg',
        mimeType: ['image/jpeg'],
      },
      '.png': {
        description: 'Portable Network Graphics',
        extension: '.png',
        mimeType: ['image/png'],
      },
      '.svg': {
        description: 'Scalable Vector Graphics (SVG)',
        extension: '.svg',
        mimeType: ['image/svg+xml'],
      },
      '.tif': {
        description: 'Tagged Image File Format (TIFF)',
        extension: '.tif',
        mimeType: ['image/tiff'],
      },
      '.tiff': {
        description: 'Tagged Image File Format (TIFF)',
        extension: '.tiff',
        mimeType: ['image/tiff'],
      },
      '.webp': {
        description: 'WEBP image',
        extension: '.webp',
        mimeType: ['image/webp'],
      },
    },
    name: 'IMAGE',
    uti: ['public.image'],
  },
  OTHER: {
    extensions: {
      '.arc': {
        description: 'Archive document (multiple files embedded)',
        extension: '.arc',
        mimeType: ['application/octet-stream'],
      },
      '.bin': {
        description: 'Any kind of binary data',
        extension: '.bin',
        mimeType: ['application/octet-stream'],
      },
      '.mpkg': {
        description: 'Apple Installer Package',
        extension: '.mpkg',
        mimeType: ['application/vnd.apple.installer+xml'],
      },
    },
    name: 'OTHER',
    uti: ['public.archive', 'public.data', 'public.executable'],
  },
  PRESENTATION: {
    extensions: {
      '.odp': {
        description: 'OpenDocument presentation document',
        extension: '.odp',
        mimeType: ['application/vnd.oasis.opendocument.presentation'],
      },
      '.pdf': {
        description: 'Adobe Portable Document Format (PDF)',
        extension: '.pdf',
        mimeType: ['application/pdf'],
      },
      '.ppt': {
        description: 'Microsoft PowerPoint',
        extension: '.ppt',
        mimeType: ['application/vnd.ms-powerpoint'],
      },
      '.vsd': {
        description: 'Microsoft Visio',
        extension: '.vsd',
        mimeType: ['application/vnd.visio'],
      },
    },
    name: 'PRESENTATION',
    uti: ['public.data', 'public.presentation'],
  },
  PROGRAMMING: {
    extensions: {
      '.csh': {
        description: 'C-Shell script',
        extension: '.csh',
        mimeType: ['application/x-csh'],
      },
      '.css': {
        description: 'Cascading Style Sheets (CSS)',
        extension: '.css',
        mimeType: ['text/css'],
      },
      '.htm': {
        description: 'HyperText Markup Language (HTML)',
        extension: '.htm',
        mimeType: ['text/html'],
      },
      '.html': {
        description: 'HyperText Markup Language (HTML)',
        extension: '.html',
        mimeType: ['text/html'],
      },
      '.jar': {
        description: 'Java Archive (JAR)',
        extension: '.jar',
        mimeType: ['application/java-archive'],
      },
      '.js': {
        description: 'JavaScript (ECMAScript)',
        extension: '.js',
        mimeType: ['application/javascript'],
      },
      '.json': {
        description: 'JSON format',
        extension: '.json',
        mimeType: ['application/json'],
      },
      '.sh': {
        description: 'Bourne shell script',
        extension: '.sh',
        mimeType: ['application/x-sh'],
      },
      '.swf': {
        description: 'Small web format (SWF) or Adobe Flash document',
        extension: '.swf',
        mimeType: ['application/x-shockwave-flash'],
      },
      '.ts': {
        description: 'Typescript file',
        extension: '.ts',
        mimeType: ['application/typescript'],
      },
      '.txt': {
        description: 'Text, (generally ASCII or ISO 8859-n)',
        extension: '.txt',
        mimeType: ['text/plain'],
      },
      '.xhtml': {
        description: 'XHTML',
        extension: '.xhtml',
        mimeType: ['application/xhtml+xml'],
      },
      '.xml': {
        description: 'XML',
        extension: '.xml',
        mimeType: ['application/xml'],
      },
      '.xul': {
        description: 'XUL',
        extension: '.xul',
        mimeType: ['application/vnd.mozilla.xul+xml'],
      },
    },
    name: 'PROGRAMMING',
    uti: [
      'public.archive',
      'public.data',
      'public.executable',
      'public.plain-text',
      'public.source-code',
      'public.text',
    ],
  },
  SPREADSHEET: {
    extensions: {
      '.csv': {
        description: 'Comma-separated values (CSV)',
        extension: '.csv',
        mimeType: ['text/csv'],
      },
      '.ods': {
        description: 'OpenDocument spreadsheet document',
        extension: '.ods',
        mimeType: ['application/vnd.oasis.opendocument.spreadsheet'],
      },
      '.xls': {
        description: 'Microsoft Excel',
        extension: '.xls',
        mimeType: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
      },
      '.xlsx': {
        description: 'Microsoft Excel',
        extension: '.xlsx',
        mimeType: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ],
      },
    },
    name: 'SPREADSHEET',
    uti: ['public.data'],
  },
  TEXT: {
    extensions: {
      '.css': {
        description: 'Cascading Style Sheets (CSS)',
        extension: '.css',
        mimeType: ['text/css'],
      },
      '.csv': {
        description: 'Comma-separated values (CSV)',
        extension: '.csv',
        mimeType: ['text/csv'],
      },
      '.htm': {
        description: 'HyperText Markup Language (HTML)',
        extension: '.htm',
        mimeType: ['text/html'],
      },
      '.html': {
        description: 'HyperText Markup Language (HTML)',
        extension: '.html',
        mimeType: ['text/html'],
      },
      '.ics': {
        description: 'iCalendar format',
        extension: '.ics',
        mimeType: ['text/calendar'],
      },
      '.txt': {
        description: 'Text, (generally ASCII or ISO 8859-n)',
        extension: '.txt',
        mimeType: ['text/plain'],
      },
    },
    name: 'TEXT',
    uti: ['public.text', 'public.plain-text'],
  },
  VIDEO: {
    extensions: {
      '.3gp': {
        description: '3GPP video container',
        extension: '.3gp',
        mimeType: ['video/3gpp'],
      },
      '.3g2': {
        description: '3GPP2 video container',
        extension: '.3g2',
        mimeType: ['video/3gpp2'],
      },
      '.3gpp': {
        description: '3GPP Multimedia File',
        extension: '.3gpp',
        mimeType: ['video/3gpp'],
      },
      '.3gpp2': {
        description: '3GPP2 Multimedia File',
        extension: '.3gpp2',
        mimeType: ['video/3gpp2'],
      },
      '.asf': {
        description: 'Advanced Systems Format File',
        extension: '.asf',
        mimeType: ['video/asf', 'video/x-asf'],
      },
      '.avi': {
        description: 'Audio Video Interleave File',
        extension: '.avi',
        mimeType: ['video/x-msvideo'],
      },
      '.flv': {
        description: 'Animate Video File',
        extension: '.flv',
        mimeType: ['video/x-flv'],
      },
      '.m4v': {
        description: 'iTunes Video File',
        extension: '.m4v',
        mimeType: ['video/mp4'],
      },
      '.mov': {
        description: 'Apple QuickTime Movie',
        extension: '.mov',
        mimeType: ['video/quicktime', 'video/x-quicktime'],
      },
      '.mp4': {
        description: 'MPEG-4 Video file',
        extension: '.mp4',
        mimeType: ['video/mp4', 'video/mp4v-es'],
      },
      '.mpeg': {
        description: 'MPEG Video File',
        extension: '.mpeg',
        mimeType: ['video/mpg', 'video/x-mpg', 'video/mpeg', 'video/x-mpeg'],
      },
      '.mpg': {
        description: 'MPEG Video File',
        extension: '.mpg',
        mimeType: ['video/mpg', 'video/x-mpg', 'video/mpeg', 'video/x-mpeg'],
      },
      '.ogv': {
        description: 'OGG video',
        extension: '.ogv',
        mimeType: ['video/ogg'],
      },
      '.ogx': {
        description: 'OGG',
        extension: '.ogx',
        mimeType: ['application/ogg'],
      },
      '.rm': {
        description: 'RealMedia File',
        extension: '.rm',
        mimeType: ['video/x-realaudio'],
      },
      '.srt': {
        description: 'SubRip Subtitle File',
        extension: '.srt',
        mimeType: ['application/octet-stream'],
      },
      '.swf': {
        description: 'Shockwave Flash Movie',
        extension: '.swf',
        mimeType: ['application/x-shockwave-flash'],
      },
      '.vob': {
        description: 'DVD Video Object File',
        extension: '.vob',
        mimeType: ['video/dvd', 'video/mpeg'],
      },
      '.webm': {
        description: 'WEBM video',
        extension: '.webm',
        mimeType: ['video/webm'],
      },
      '.wmv': {
        description: 'Windows Media Video File',
        extension: '.wmv',
        mimeType: ['video/x-ms-wmv'],
      },
    },
    name: 'VIDEO',
    uti: ['public.movie'],
  },

  // SPECIAL JUNCTIONS
  IMAGE_OR_VIDEO: {
    extensions: {
      // IMAGE
      '.gif': {
        description: 'Graphics Interchange Format (GIF)',
        extension: '.gif',
        mimeType: ['image/gif'],
      },
      '.ico': {
        description: 'Icon format',
        extension: '.ico',
        mimeType: ['image/x-icon'],
      },
      '.jpeg': {
        description: 'JPEG images',
        extension: '.jpeg',
        mimeType: ['image/jpeg'],
      },
      '.jpg': {
        description: 'JPEG images',
        extension: '.jpg',
        mimeType: ['image/jpeg'],
      },
      '.png': {
        description: 'Portable Network Graphics',
        extension: '.png',
        mimeType: ['image/png'],
      },
      '.svg': {
        description: 'Scalable Vector Graphics (SVG)',
        extension: '.svg',
        mimeType: ['image/svg+xml'],
      },
      '.tif': {
        description: 'Tagged Image File Format (TIFF)',
        extension: '.tif',
        mimeType: ['image/tiff'],
      },
      '.tiff': {
        description: 'Tagged Image File Format (TIFF)',
        extension: '.tiff',
        mimeType: ['image/tiff'],
      },
      '.webp': {
        description: 'WEBP image',
        extension: '.webp',
        mimeType: ['image/webp'],
      },
      // /IMAGE

      // VIDEO
      '.3gp': {
        description: '3GPP video container',
        extension: '.3gp',
        mimeType: ['video/3gpp'],
      },
      '.3g2': {
        description: '3GPP2 video container',
        extension: '.3g2',
        mimeType: ['video/3gpp2'],
      },
      '.3gpp': {
        description: '3GPP Multimedia File',
        extension: '.3gpp',
        mimeType: ['video/3gpp'],
      },
      '.3gpp2': {
        description: '3GPP2 Multimedia File',
        extension: '.3gpp2',
        mimeType: ['video/3gpp2'],
      },
      '.asf': {
        description: 'Advanced Systems Format File',
        extension: '.asf',
        mimeType: ['video/asf', 'video/x-asf'],
      },
      '.avi': {
        description: 'Audio Video Interleave File',
        extension: '.avi',
        mimeType: ['video/x-msvideo'],
      },
      '.flv': {
        description: 'Animate Video File',
        extension: '.flv',
        mimeType: ['video/x-flv'],
      },
      '.m4v': {
        description: 'iTunes Video File',
        extension: '.m4v',
        mimeType: ['video/mp4'],
      },
      '.mov': {
        description: 'Apple QuickTime Movie',
        extension: '.mov',
        mimeType: ['video/quicktime', 'video/x-quicktime'],
      },
      '.mp4': {
        description: 'MPEG-4 Video file',
        extension: '.mp4',
        mimeType: ['video/mp4', 'video/mp4v-es'],
      },
      '.mpeg': {
        description: 'MPEG Video File',
        extension: '.mpeg',
        mimeType: ['video/mpg', 'video/x-mpg', 'video/mpeg', 'video/x-mpeg'],
      },
      '.mpg': {
        description: 'MPEG Video File',
        extension: '.mpg',
        mimeType: ['video/mpg', 'video/x-mpg', 'video/mpeg', 'video/x-mpeg'],
      },
      '.ogv': {
        description: 'OGG video',
        extension: '.ogv',
        mimeType: ['video/ogg'],
      },
      '.ogx': {
        description: 'OGG',
        extension: '.ogx',
        mimeType: ['application/ogg'],
      },
      '.rm': {
        description: 'RealMedia File',
        extension: '.rm',
        mimeType: ['video/x-realaudio'],
      },
      '.srt': {
        description: 'SubRip Subtitle File',
        extension: '.srt',
        mimeType: ['application/octet-stream'],
      },
      '.swf': {
        description: 'Shockwave Flash Movie',
        extension: '.swf',
        mimeType: ['application/x-shockwave-flash'],
      },
      '.vob': {
        description: 'DVD Video Object File',
        extension: '.vob',
        mimeType: ['video/dvd', 'video/mpeg'],
      },
      '.webm': {
        description: 'WEBM video',
        extension: '.webm',
        mimeType: ['video/webm'],
      },
      '.wmv': {
        description: 'Windows Media Video File',
        extension: '.wmv',
        mimeType: ['video/x-ms-wmv'],
      },
      // /VIDEO
    },
    name: 'IMAGE_OR_VIDEO',
    uti: ['public.image', 'public.movie'],
  },

  FILE: {
    extensions: {},
    name: 'FILE',
    uti: {},
  },
};

FILES_CONSTANTS_BY_TYPE.FILE = {
  extensions: {
    ...FILES_CONSTANTS_BY_TYPE.IMAGE_OR_VIDEO.extensions,
    ...FILES_CONSTANTS_BY_TYPE.AUDIO.extensions,
    ...FILES_CONSTANTS_BY_TYPE.DOCUMENT.extensions,
  },
  name: 'FILE',
  uti: [
    ...FILES_CONSTANTS_BY_TYPE.IMAGE_OR_VIDEO.uti,
    ...FILES_CONSTANTS_BY_TYPE.AUDIO.uti,
    ...FILES_CONSTANTS_BY_TYPE.DOCUMENT.uti,
  ],
};
