<div
  [ngClass]="{ 'custom-form--remove-padding': removePadding }"
  *ngIf="!loading && customForms?.length"
  class="custom-form"
>
  <ng-container *ngFor="let customForm of customForms">
    <form [formGroup]="customForm.form" class="custom-form__form">
      <div class="field__form">
        <ng-container
          [ngSwitch]="field.type"
          *ngFor="let field of customForm.fields"
        >
          <ng-container *ngIf="field.isValidField">
            <div *ngSwitchCase="customFormFieldTypes.TEXT" class="field__group">
              <label class="field__label">
                {{ field.label }}
              </label>
              <input
                (input)="validateForm()"
                class="field__input"
                formControlName="{{ field.name }}"
                placeholder="{{ field.placeholder }}"
              />
            </div>
            <div
              *ngSwitchCase="customFormFieldTypes.SELECT"
              class="field__group"
            >
              <label class="field__label">
                {{ field.label }}
              </label>
              <mat-select
                (selectionChange)="validateForm()"
                formControlName="{{ field.name }}"
                placeholder="{{ field.placeholder }}"
                class="field__input"
              >
                <mat-option
                  *ngFor="let option of field.options"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </div>

            <div
              *ngSwitchCase="customFormFieldTypes.MULTI_SELECT"
              class="field__group"
            >
              <label class="field__label">
                {{ field.label }}
              </label>
              <mat-select
                (selectionChange)="validateForm()"
                formControlName="{{ field.name }}"
                placeholder="{{ field.placeholder }}"
                class="field__input"
                multiple
              >
                <mat-option
                  *ngFor="let option of field.options"
                  [value]="option.value"
                >
                  {{ option.label }}
                </mat-option>
              </mat-select>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </form>
  </ng-container>

  <div class="custom-form__agreement field__group">
    <mat-checkbox [(ngModel)]="acceptedTerms">
      <p>{{ 'AUT.FORM.FIELDS.AGREEMENT.LABEL' | translate }}</p>
      <div class="custom-form__check-documents">
        <a
          *ngFor="let item of legalDocuments"
          href="{{ item.href }}"
          target="_blank"
        >
          <small class="label label--centered label--underlined">{{
            item.title | translate
          }}</small>
        </a>
        <span> {{ hubInstance?.content?.name }} </span>
      </div>
    </mat-checkbox>
  </div>

  <div class="custom-form__actions">
    <button
      id="bt__custom-form-save"
      *ngIf="!isEditMode"
      class="custom-form__register-button btn btn--full-width"
      [disabled]="!formsValid || !acceptedTerms || isSaving"
      (click)="saveForm()"
    >
      <mat-spinner *ngIf="isSaving"></mat-spinner>
      <span *ngIf="!isSaving">{{ 'REGISTER' | translate }}</span>
    </button>

    <button
      id="bt__custom-form-edit"
      *ngIf="isEditMode"
      class="btn btn--sm"
      [disabled]="!formsValid || !acceptedTerms || isSaving"
      (click)="editForm()"
    >
      <mat-spinner *ngIf="isSaving"></mat-spinner>
      <span *ngIf="!isSaving">{{ 'SAVE' | translate }}</span>
    </button>
  </div>
</div>

<!-- Skeleton start -->
<ng-container *ngIf="loading">
  <div *ngFor="let item of [1, 2, 3, 4, 5]" style="width: 100%">
    <div
      [medSkeletonLoading]="true"
      style="width: 100%; height: 60px; margin-top: 24px"
    ></div>
  </div>
</ng-container>
<!-- Skeleton end -->
