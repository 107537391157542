import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MentionDirective } from '../../../directives/mention.directive';
import { ProfileEntity } from '../../../common/models/profile/profile-entity';
import { NetworkConstants } from '../../../common/constants/network.constants';
import { PaginationOptions } from '../../../common/models/common/pagination-options';
import { Subscription } from 'rxjs';
import { ApiProfileV3Service } from '../../../services/api/api-profile-v3.service';
import { ToastService } from '../../../services/toast.service';

@Component({
  selector: 'mec-social-textarea',
  imports: [CommonModule, FormsModule, TranslateModule, MentionDirective],
  providers: [ApiProfileV3Service],
  templateUrl: './social-textarea.component.html',
  styleUrls: ['./social-textarea.component.scss'],
})
export class SocialTextareaComponent implements OnDestroy {
  @Input() public socialNetworkId: string;
  @Output() public socialPublicationBodyChange = new EventEmitter<string>();

  public bodyText: string;
  public inputText: string;
  public isLoading: boolean;
  public profileMentionSubscription: Subscription;
  public pagination: PaginationOptions = {
    limit: 20,
    orderBy: 'desc',
  };

  public items: ProfileEntity[] = [];

  constructor(
    private _apiProfileV3Service: ApiProfileV3Service,
    private _toastService: ToastService
  ) {}

  public ngOnDestroy(): void {
    this.profileMentionSubscription?.unsubscribe();
  }

  public changeBodyText(body: string): void {
    const pattern = NetworkConstants.patterns.find(
      (patttern) => patttern.name === 'mentionText'
    );
    this.inputText = body.replace(pattern.formula, pattern.output.directive);
    this.socialPublicationBodyChange.emit(body);
  }

  public loadParticipants(searchValue: string): void {
    this.isLoading = true;
    this.items = [];

    if (this.profileMentionSubscription) {
      this.profileMentionSubscription.unsubscribe();
    }

    try {
      this.profileMentionSubscription = this._apiProfileV3Service
        .getParticipantsByMention(
          this.pagination,
          this.socialNetworkId,
          searchValue
        )
        .subscribe((participantsResult) => {
          this.items = participantsResult;
          this.isLoading = false;
        });
    } catch (error) {
      this._toastService.showError(error);
      this.isLoading = false;
    }
  }
}
