import { Language } from '../enums/language/language';
import { LanguageFilter } from '../models/common/language/language-filter';

export const LanguagesFilter: LanguageFilter[] = [
  {
    name: 'LANGUAGE.ENGLISH',
    countryName: 'LANGUAGE.COUNTRY.ENGLAND',
    isSelected: true,
    isDisabled: true,
    language: Language.EN,
    isGlobal: true,
    flagCode: 'gb',
  },
  {
    name: 'LANGUAGE.GERMAN',
    countryName: 'LANGUAGE.COUNTRY.GERMANY',
    language: Language.DE,
    isGlobal: true,
    flagCode: 'de',
  },
  {
    name: 'LANGUAGE.SPANISH',
    countryName: 'LANGUAGE.COUNTRY.SPAIN',
    language: Language.ES,
    isGlobal: true,
    flagCode: 'es',
  },
  {
    name: 'LANGUAGE.FRENCH',
    countryName: 'LANGUAGE.COUNTRY.FRANCE',
    language: Language.FR,
    isGlobal: true,
    flagCode: 'fr',
  },
  {
    name: 'LANGUAGE.ITALIAN',
    countryName: 'LANGUAGE.COUNTRY.ITALY',
    isSelected: false,
    language: Language.IT,
    isGlobal: true,
    flagCode: 'it',
  },
  {
    name: 'LANGUAGE.JAPANESE',
    countryName: 'LANGUAGE.COUNTRY.JAPAN',
    language: Language.JA,
    isGlobal: true,
    flagCode: 'jp',
  },
  {
    name: 'LANGUAGE.KOREAN',
    countryName: 'LANGUAGE.COUNTRY.KOREA',
    language: Language.KO,
    isGlobal: true,
    flagCode: 'kr',
  },
  {
    name: 'LANGUAGE.PORTUGUESE',
    countryName: 'LANGUAGE.COUNTRY.PORTUGAL',
    language: Language.PT,
    isGlobal: true,
    flagCode: 'pt',
  },
  {
    name: 'LANGUAGE.BRAZILIAN_PORTUGUESE',
    countryName: 'LANGUAGE.COUNTRY.BRAZIL',
    language: Language.PT_BR,
    isGlobal: true,
    flagCode: 'br',
  },
  {
    name: 'LANGUAGE.CHINESE',
    countryName: 'LANGUAGE.COUNTRY.CHINA',
    language: Language.ZH,
    isGlobal: true,
    flagCode: 'cn',
  },
  {
    name: 'LANGUAGE.DUTCH',
    countryName: 'LANGUAGE.COUNTRY.NETHERLANDS',
    language: Language.NL,
    isGlobal: true,
    flagCode: 'nl',
  },
];
