import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AppRouteConfig } from '../common/models/common/app-route-config';

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbsService {
  private _breadcrumbsSubject = new BehaviorSubject<AppRouteConfig[]>([]);
  public _breadcrumbsObservable = this._breadcrumbsSubject.asObservable();

  public async setBreadcrumbs(breadcrumbs: AppRouteConfig[]): Promise<void> {
    this._breadcrumbsSubject.next(breadcrumbs);
  }
}
