import { DirectionOptions } from '../../enums/common/direction-options';
import { ProfileGender } from '../../enums/profile/profile-gender';
import { SocialNetworkConnection } from '../../enums/social-network/social-network-connection';
import { Language } from '../../enums/language/language';
import { PaginationOptions } from '../common/pagination-options';
import { ProfileStatistics } from '../profile/profile-statistics';

export class SocialNetworkParticipant {
  public id: number;
  public age: number;
  public avatar: number;
  public biography: string;
  public birthday: Date;
  public connection: SocialNetworkConnection;
  public country: string;
  public defaultLanguage: Language;
  public enabled: boolean;
  public firstname: string;
  public fullName: string;
  public lastName: string;
  public login: string;
  public name: string;
  public following: boolean;
  public gender: ProfileGender;
  public healthProfessional: boolean;
  public statistics: ProfileStatistics;
  public profileId?: number;
}

export class SocialNetworkParticipantsList {
  public participants: SocialNetworkParticipant[];
  public pagination: PaginationOptions = {
    direction: DirectionOptions.desc,
    limit: 20,
    page: 0,
  };
}
