import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHelper } from '@meplis/common';
import { AuthenticationService } from 'libs/meplis/src/lib/services/authentication.service';

@Component({
  selector: 'files-video',
  imports: [CommonModule],
  templateUrl: './files-video.component.html',
  styleUrl: './files-video.component.scss',
})
export class FilesVideoComponent {
  @Input() data: any;
  public url: string;

  constructor(private _authService: AuthenticationService) {}

  public async ngOnInit(): Promise<void> {
    if (this.data?.fileId) {
      this.url = CommonHelper.getFileUrl(this.data?.fileId, {
        quality: 720,
        width: 352,
        format: 'mp4',
        access_token: this._authService.getToken(),
      });
    }
  }
}
