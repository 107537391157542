import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CourseCardComponent } from '../course-card/course-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { Course, CoursesList } from '../../../common/models/course/courses';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ApiCoursesV2Service } from '../../../services/api/api-courses-v2.service';
import { ToastService } from '../../../services/toast.service';
import { ApiCoursesV1Service } from '../../../services/api/api-courses-v1.service';
import { CommonService } from '../../../services/common.service';
import { DirectionOptions } from '../../../common/enums/common/direction-options';
import { Routes } from '../../../common/constants/routes.constants';

@Component({
  selector: 'mec-course-card-list',
  imports: [
    CommonModule,
    SkeletonDirective,
    TranslateModule,
    CourseCardComponent,
    InfiniteScrollDirective,
  ],
  providers: [ApiCoursesV2Service],
  templateUrl: './course-card-list.component.html',
  styleUrls: ['./course-card-list.component.scss'],
})
export class CourseCardListComponent implements OnInit, OnChanges {
  @Input() public limit: number;
  @Input() public maxPage: number;

  public coursesList: CoursesList = new CoursesList();
  public isLoadingCourses: boolean;
  public isLoadingMore: boolean;

  constructor(
    private _apiCoursesV1Service: ApiCoursesV1Service,
    private _commonService: CommonService,
    private _toastService: ToastService
  ) {}

  public ngOnChanges(): void {
    if (this.limit) {
      this.coursesList.pagination.limit = this.limit;
    }
    if (this.maxPage) {
      this.coursesList.pagination.maxPage = this.maxPage;
    }
  }

  public async ngOnInit(): Promise<void> {
    this.getCourses();
  }

  public goToPage(): void {
    this._commonService.navigate([Routes['courses'].fullPath]);
  }

  public async getCourses(reset?: boolean): Promise<void> {
    this.isLoadingCourses = true;
    this.coursesList.pagination.isLoadingMore = true;

    if (reset) {
      this.coursesList = new CoursesList();
    }

    try {
      const coursesResult = await this._apiCoursesV1Service.getCourses(
        this.coursesList.pagination
      );

      this.coursesList = PaginationHelper.newHandlePagination<Course>(
        this.coursesList,
        'courses',
        coursesResult.courses
      );
      this.coursesList;
    } catch (error: any) {
      this._toastService.showError(error);
    }

    this.isLoadingCourses = false;
    this.coursesList.pagination.isLoadingMore = false;
  }
}
