import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BreadcrumbsService } from '../../../services/breadcrumbs.service';
import { AuthenticationService } from '../../../services/authentication.service';
import { Routes } from '../../../common/constants/routes.constants';
import { TranslateModule } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { SettingsService } from '../../../services/settings.service';
import { AppRouteConfig } from '../../../common/models/common/app-route-config';

@Component({
  selector: 'mec-breadcrumbs',
  imports: [CommonModule, TranslateModule],
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
})
export class BreadcrumbsComponent implements OnDestroy {
  public breadcrumbs: AppRouteConfig[] = [];

  public routerSubscription: Subscription;
  public breadcrumbsSubscription: Subscription;
  public previuosParams: Params;

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private _breacrumbsService: BreadcrumbsService,
    private _authService: AuthenticationService,
    private _commonService: CommonService,
    private _settingsService: SettingsService
  ) {
    this.breadcrumbsSubscription =
      this._breacrumbsService._breadcrumbsObservable.subscribe(
        async (breadcrumbs) => {
          this.breadcrumbs = breadcrumbs;

          if (!this.breadcrumbs || !this.breadcrumbs.length) {
            return;
          }

          if (this._authService.isLogged) {
            const defaultLoggedRoute =
              await this._settingsService.getPermissionByName(
                'DEFAULT_LOGGED_STATE'
              );

            this.breadcrumbs.unshift(
              Routes[defaultLoggedRoute.value.toLowerCase()]
            );
          } else {
            this.breadcrumbs.unshift(Routes['home']);
          }
        }
      );
  }

  public ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
    this.breadcrumbsSubscription?.unsubscribe();
  }

  public goToRoute(path: string): void {
    this._commonService.navigate([path]);
  }
}
