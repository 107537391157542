export enum Language {
  EN = 'en',
  DE = 'de',
  ES = 'es',
  ES_LA = 'es-la',
  FR = 'fr',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  NL = 'nl',
  NO = 'no',
  PT = 'pt',
  PT_BR = 'pt-br',
  RU = 'ru',
  SV = 'sv',
  ZH = 'zh',
}
