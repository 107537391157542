import { CommonModule } from '@angular/common';
import { Component, Input, OnChanges } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CommonHelper } from '../../../common/helpers/common.helper';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { SurveyEntity } from '../../../common/models/survey/survey-entity';
import { TagsListComponent } from '../../common/tags-list/tags-list.component';

@Component({
  selector: 'mec-survey-card',
  imports: [
    CommonModule,
    SkeletonDirective,
    TranslateModule,
    TagsListComponent,
  ],
  templateUrl: './survey-card.component.html',
  styleUrls: ['./survey-card.component.scss'],
})
export class SurveyCardComponent implements OnChanges {
  @Input() survey: SurveyEntity;

  public imageUrl: string;
  public hiddenImage: boolean;
  public isLoadingImage = true;

  constructor(private _commonService: CommonService) {}

  public ngOnChanges(): void {
    if (this.survey?.content?.defaultImageHash) {
      this.imageUrl = CommonHelper.getUrlFile(
        this.survey.content.defaultImageHash
      );
    }
  }

  public goToSurveyDetail(): void {
    this._commonService.navigate([Routes['actions'].fullPath, this.survey.id]);
  }
}
