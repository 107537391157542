import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { InfiniteScrollDirective } from '../../../directives/infinite-scroll.directive';
import { SkeletonDirective } from '../../../directives/skeleton.directive';
import { ToastService } from '../../../services/toast.service';
import { FooterComponent } from '../../common/footer/footer.component';
import { SurveyCardComponent } from '../survey-card/survey-card.component';
import { SettingsService } from '../../../services/settings.service';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';
import { ApiSurveyService } from '../../../services/api/api-surveys.service';
import { SurveyEntity } from '../../../common/models/survey/survey-entity';
import { SurveyList } from '../../../common/models/survey/survey-list';

@Component({
  selector: 'mec-survey-card-list',
  imports: [
    CommonModule,
    SkeletonDirective,
    TranslateModule,
    SurveyCardComponent,
    FooterComponent,
    InfiniteScrollDirective,
  ],
  providers: [ApiSurveyService],
  templateUrl: './survey-card-list.component.html',
  styleUrls: ['./survey-card-list.component.scss'],
})
export class SurveyCardListComponent implements OnInit {
  public surveyList: SurveyList = new SurveyList();
  public loadMore = true;
  public isLoadingMore = true;
  public isLoadingSurvey = true;
  public disableSurvey: boolean;

  constructor(
    private _apiSurveyService: ApiSurveyService,
    private _settingsService: SettingsService,
    private _toastService: ToastService,
    private _commonService: CommonService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.disableSurvey = await this._settingsService._getPermissionValue(
      'DISABLE_SURVEY'
    );

    if (this.disableSurvey) {
      return;
    }

    await this.getSurveysMe();
  }

  public goToPage(): void {
    this._commonService.navigate([Routes['actions'].fullPath]);
  }

  public async getSurveysMe(reset?: boolean): Promise<void> {
    this.loadMore = this.isLoadingMore = this.isLoadingSurvey = true;

    if (reset) {
      this.surveyList = new SurveyList();
    }

    try {
      const itemsToAdd = await this._apiSurveyService.getSurveysMe();
      this.surveyList.surveys = PaginationHelper.handlePagination<SurveyEntity>(
        this.surveyList.surveys,
        itemsToAdd,
        reset
      );
    } catch (error: any) {
      this._toastService.showError(error);
    }

    // mleon(): this request doesn't has pagination
    this.loadMore = this.isLoadingMore = this.isLoadingSurvey = false;
  }
}
