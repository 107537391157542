import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import { ToastService } from '../../../services/toast.service';
import { ApiChainService } from '../../../services/api/api-chain.service';
import { ProgramService } from '../../../services/program.service';

@Component({
  selector: 'mec-program-arithmetic',
  imports: [CommonModule],
  templateUrl: './program-arithmetic.component.html',
  styleUrls: ['./program-arithmetic.component.scss'],
})
export class ProgramArithmeticComponent implements OnChanges {
  @Input() public programNode: ProgramNode;
  @Input() public logs: ProgramLog[];
  @Input() public programToken: string;

  public message: string;

  constructor(
    private _toastService: ToastService,
    private _apiChainService: ApiChainService,
    private _programService: ProgramService
  ) {
    this._programService.onStepDependecyChange.subscribe(
      async (dependencyId: string) => {
        if (
          `${this.programNode.project}::${dependencyId}` === this.programNode.id
        ) {
          const logsWithoutOwn = this.logs.filter(
            (log) => log.to && log.to !== this.programNode.id
          );
          await this._executeSentece(logsWithoutOwn);
        }
      }
    );
  }

  public ngOnChanges(changes: SimpleChanges): void {
    console.log(changes);
  }

  private async _executeSentece(logs: ProgramLog[]): Promise<void> {
    try {
      console.log(logs);

      this.programNode.sentenceValue =
        await this._apiChainService.executeSentence(
          this.programToken,
          this.programNode,
          logs
        );

      this._verifyIfHasDependencies();
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  private _verifyIfHasDependencies(): void {
    if (!this.programNode.dependencies?.length) {
      return;
    }

    this.programNode.dependencies.forEach((dependencyId) => {
      this._programService.setStepDependency(dependencyId);
    });
  }
}
