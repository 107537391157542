import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ProgramNode } from '../../../common/models/care-monitor/program-node';
import { ProgramRadioComponent } from '../program-radio/program-radio.component';
import { TranslateModule } from '@ngx-translate/core';
import { ProgramCheckboxComponent } from '../program-checkbox/program-checkbox.component';
import { ProgramOpenTextComponent } from '../program-open-text/program-open-text.component';
import { ProgramOutputComponent } from '../program-output/program-output.component';
import { ProgramEqvasComponent } from '../program-eqvas/program-eqvas.component';
import { ProgramMatrixRadioComponent } from '../program-matrix-radio/program-matrix-radio.component';
import { ProgramNumberComponent } from '../program-number/program-number.component';
import { ProgramMediaComponent } from '../program-media/program-media.component';
import { ProgramDateComponent } from '../program-date/program-date.component';
import { ProgramTextComponent } from '../program-text/program-text.component';
import { ProgramLog } from '../../../common/models/care-monitor/program-log';
import { ProgramPolicyComponent } from '../program-policy/program-policy.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: 'mec-program-step-container',
  standalone: true,
  imports: [
    CommonModule,
    ProgramRadioComponent,
    ProgramCheckboxComponent,
    ProgramOpenTextComponent,
    ProgramOutputComponent,
    ProgramEqvasComponent,
    ProgramMatrixRadioComponent,
    ProgramNumberComponent,
    ProgramMediaComponent,
    ProgramDateComponent,
    ProgramTextComponent,
    ProgramPolicyComponent,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './program-step-container.component.html',
  styleUrls: ['./program-step-container.component.scss'],
})
export class ProgramStepContainerComponent implements OnChanges {
  @Input() public programStep: ProgramNode[];
  @Input() public isLoadingStep: boolean;
  @Output() onStepValidChange = new EventEmitter<boolean>();

  constructor(@Inject(DOCUMENT) private _document: Document) {}

  public ngOnChanges(): void {
    if (this.programStep) {
      console.log(this.programStep);
    }
  }
  private _goToNextNode(stepIndex: number): void {
    const el = this._document.getElementById(`node${stepIndex}`);
    if (el) {
      el.scrollIntoView({ behavior: 'smooth' });
    }
  }

  public validateNode(
    stepIndex: number,
    programLog: ProgramLog,
    valid: boolean
  ): void {
    this.programStep[stepIndex].isValid =
      programLog?.results?.length > 0 && valid;

    const isStepValid = !this.programStep.some((program) => !program.isValid);
    this.onStepValidChange.emit(isStepValid);

    if (!this.programStep[stepIndex].isValid) {
      return;
    }

    this.programStep[stepIndex].log = programLog;

    if (
      this.programStep[stepIndex].type === 'radio' ||
      this.programStep[stepIndex].type === 'eqvas' ||
      this.programStep[stepIndex].type === 'date'
    ) {
      this._goToNextNode(stepIndex);
    }
  }
}
