<div class="program-step-container">
  <div class="program-step-container__items">
    <ng-container *ngFor="let item of programStep; let i = index" #node1>
      <div
        class="program-step-container__start-item card"
        *ngIf="item.type === 'start'"
      >
        <h4>{{ item?.title }}</h4>
        <span>{{ item?.description }}</span>
      </div>

      <mec-program-number
        class="program-step-container__item"
        *ngIf="item.type === 'number'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event.programLog, $event.valid)"
        [programNode]="item"
      ></mec-program-number>

      <mec-program-radio
        class="program-step-container__item"
        *ngIf="item.type === 'radio'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event, true)"
        [programNode]="item"
      ></mec-program-radio>

      <mec-program-policy
        class="program-step-container__item"
        *ngIf="item.type === 'policy'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event.programLog, $event.valid)"
        [programNode]="item"
      ></mec-program-policy>

      <mec-program-checkbox
        class="program-step-container__item"
        *ngIf="item.type === 'checkbox'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event, true)"
        [programNode]="item"
      >
      </mec-program-checkbox>

      <mec-program-open-text
        class="program-step-container__item"
        *ngIf="item.type === 'openText'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event.programLog, $event.valid)"
        [programNode]="item"
      ></mec-program-open-text>

      <mec-program-text
        class="program-step-container__item"
        *ngIf="item.type === 'text'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event.programLog, $event.valid)"
        [programNode]="item"
      ></mec-program-text>

      <mec-program-date
        class="program-step-container__item"
        *ngIf="item.type === 'date'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event.programLog, $event.valid)"
        [programNode]="item"
      ></mec-program-date>

      <mec-program-output
        class="program-step-container__item"
        *ngIf="item.type === 'output'"
        id="node{{ i }}"
        [logs]="logs"
        [programNode]="item"
        [programToken]="programToken"
        [dependecies]="dependecies"
      ></mec-program-output>

      <mec-program-arithmetic
        class="program-step-container__item hidden"
        *ngIf="item.type === 'arithmetic'"
        id="node{{ i }}"
        [logs]="logs"
        [programNode]="item"
        [programToken]="programToken"
      ></mec-program-arithmetic>

      <mec-program-media
        class="program-step-container__item"
        *ngIf="item.type === 'media'"
        id="node{{ i }}"
        (onSelectItemChange)="validateNode(i, $event.programLog, $event.valid)"
        [programNode]="item"
      ></mec-program-media>

      <mec-program-eqvas
        class="program-step-container__item"
        *ngIf="item.type === 'eqvas'"
        (onSelectItemChange)="validateNode(i, $event, true)"
        id="node{{ i }}"
        [programNode]="item"
      ></mec-program-eqvas>

      <mec-program-matrix-radio
        class="program-step-container__item"
        *ngIf="item.type === 'matrix-radio'"
        (onSelectItemChange)="validateNode(i, $event, true)"
        id="node{{ i }}"
        [programNode]="item"
      ></mec-program-matrix-radio>
    </ng-container>
  </div>
</div>
