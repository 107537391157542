import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { NavigationExtras } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

import { ArticleType } from '../../../../common/enums/article/article-type';
import { CommonHelper } from '../../../../common/helpers/common.helper';
import { CommonService } from '../../../../services/common.service';
import { DropdownMenuComponent } from '../../dropdown-menu/dropdown-menu.component';
import { Routes } from '../../../../common/constants/routes.constants';
import { SearchResult } from '../../../../common/models/common/search/search-result';
import { SkeletonDirective } from '../../../../directives/skeleton.directive';
import { TagsListComponent } from '../../tags-list/tags-list.component';
import { AppRouteConfig } from 'libs/meplis/src/lib/common/models/common/app-route-config';

@Component({
  selector: 'mec-search-card',
  imports: [
    CommonModule,
    DropdownMenuComponent,
    TagsListComponent,
    TranslateModule,
    SkeletonDirective,
  ],
  templateUrl: './search-card.component.html',
  styleUrls: ['./search-card.component.scss'],
})
export class SearchCardComponent implements OnInit {
  @Input() public searchResult: SearchResult;

  public imageUrl: string;
  public hiddenImage: boolean;
  public isLoadingImage = true;
  public isLogged: boolean;

  public routes: { [key: string]: AppRouteConfig } = {
    [ArticleType.COURSE]: Routes['course'],
    [ArticleType.PAGE]: Routes['page'],
    [ArticleType.EVENTS]: Routes['event'],
    [ArticleType.FILE]: Routes['library'],
    [ArticleType.NEWS]: Routes['newsDetail'],
  };

  constructor(private _commonService: CommonService) {}

  public ngOnInit(): void {
    if (this.searchResult?.defaultImageHash) {
      this.imageUrl = CommonHelper.getUrlFile(
        this.searchResult.defaultImageHash
      );
    } else if (this.searchResult?.content?.imageHash) {
      this.imageUrl = CommonHelper.getUrlFile(
        this.searchResult?.content?.imageHash
      );
    } else if (this.searchResult?.coverFileHash) {
      this.imageUrl = CommonHelper.getUrlFile(this.searchResult.coverFileHash);
    }
  }

  public goToRoute(searchResult: SearchResult): void {
    const route = this.routes[searchResult.type];

    if (searchResult.url) {
      this._commonService.navigate([searchResult?.url], undefined, true);
      return;
    }

    if (searchResult.type === ArticleType.FILE) {
      let extras: NavigationExtras;
      let routeWithSlug = route.fullPath;

      if (searchResult?.course && searchResult?.steps) {
        const classRoomRoute = Routes['classroom'];
        routeWithSlug = classRoomRoute.fullPath.replace(
          ':courseSlug',
          searchResult.course.slug
        );
        routeWithSlug = routeWithSlug.replace(
          ':stepId',
          searchResult.steps[0].id.toString()
        );

        const routeArray = routeWithSlug.split('/');

        extras = {
          queryParams: {
            courseId: searchResult?.course.id,
          },
        };

        this._commonService.navigate(routeArray, extras);
        return;
      }

      const routeArray = routeWithSlug.split('/');

      extras = {
        queryParams: {
          libraryFileId: searchResult?.id,
        },
      };

      this._commonService.navigate(routeArray, extras);
      return;
    }

    const routeWithSlug = route.fullPath.replace(':slug', searchResult.slug);
    const pathArray = routeWithSlug.split('/');

    this._commonService.navigate(pathArray);
  }
}
