<div
  *ngIf="(programNode?.message && !isDependency) || programNode?.messageValue"
  class="program-output card"
>
  <h4
    *ngIf="programNode?.labelText || programNode?.name"
    [innerHTML]="programNode?.labelText || programNode?.name"
  ></h4>
  <mec-dynamic-html
    *ngIf="programNode?.messageValue"
    [content]="programNode?.messageValue"
  ></mec-dynamic-html>
  <!-- <span
    *ngIf="programNode?.messageValue"
    [innerHTML]="programNode?.messageValue"
  ></span> -->
  <span
    *ngIf="!programNode?.messageValue"
    [innerHTML]="programNode?.message"
  ></span>
</div>
