import { ProfileGender } from '../../enums/profile/profile-gender';
import { ProfileStatistics } from './profile-statistics';

export class ProfileEntity {
  public age: number;
  public avatar: number;
  public avatarHash: string;
  public birthday: Date;
  public country: string; // todo(mleon): to enum "BR",
  public cover: number;
  public defaultLanguage?: string; // todo(mleon): to enum "en",
  public enabled?: boolean;
  public firstname: string;
  public fullName: string;
  public gender: ProfileGender;
  public healthProfessional: boolean;
  public id: number;
  public isPublic: boolean;
  public lastname: string;
  public login: string;
  public email: string;
  public name: string;
  public timezone?: string; // todo(mleon): to enum "BRT",
  public type?: string; // todo(mleon): to enum "PERSON",
  public biography?: string;
  public password?: string;
  public following?: boolean;
  public statistics?: ProfileStatistics;
  public evaluatorAvatarUrl?: string;
  public avatarUrl?: string;
  public owner?: boolean;
  public moderator?: boolean;
}
