import { Component, Inject, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApiSocialNetworksV1Service } from '../../../services/api/api-social-networks-V1.service';
import { SocialNetWorkThread } from '../../../common/models/social-network/social-network-thread';
import { ToastService } from '../../../services/toast.service';
import { SocialNetworkReactionList } from '../../../common/models/social-network/social-network-reaction-list';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalOptions } from '../../../common/models/modal/modal-options';
import { ModalComponent } from '../../common/modal/modal.component';
import { PaginationHelper } from '../../../common/helpers/pagination.helper';
import { SocialNetworkReaction } from '../../../common/models/social-network/social-network-reaction';
import { CommonHelper } from '../../../common/helpers/common.helper';
import { MatTabsModule } from '@angular/material/tabs';
import { SocialNetWorkReactionsInfo } from '../../../common/models/social-network/social-network-reaction-info';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SocialNetworkReactions } from '../../../common/enums/social-network/social-network-reactions';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../../services/common.service';
import { Routes } from '../../../common/constants/routes.constants';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'mec-social-reaction-list-modal',
  imports: [CommonModule, ModalComponent, MatTabsModule, FontAwesomeModule],
  providers: [ApiSocialNetworksV1Service],
  templateUrl: './social-reaction-list-modal.component.html',
  styleUrls: ['./social-reaction-list-modal.component.scss'],
})
export class SocialReactionListModalComponent implements OnInit {
  public reactionsList: SocialNetworkReactionList =
    new SocialNetworkReactionList();

  public reactionsInfo: SocialNetWorkReactionsInfo =
    new SocialNetWorkReactionsInfo();

  public groupedReactions: any[][];

  public reactionsTypes = SocialNetworkReactions;
  public modalOptions: ModalOptions;
  public arrowIcon = faChevronRight;

  constructor(
    @Inject(MAT_DIALOG_DATA) public socialThread: SocialNetWorkThread,
    public matDialogRef: MatDialogRef<SocialReactionListModalComponent>,
    private _apiSocialNetworksV1Service: ApiSocialNetworksV1Service,
    private _toastService: ToastService,
    private _translateService: TranslateService,
    private _commonService: CommonService
  ) {
    this.modalOptions = {
      title: this._translateService.instant('SOCIALNETWORK_REACTIONS'),
      closeable: true,
      hideFooter: true,
    };
  }

  public ngOnInit(): void {
    this.getGivenReactions();
  }

  public async getGivenReactions(): Promise<void> {
    try {
      let reactionsResult = await this._apiSocialNetworksV1Service.getReactions(
        this.socialThread.instancesId,
        this.socialThread.token
      );

      const groupedResult = CommonHelper.groupByProperty<SocialNetworkReaction>(
        reactionsResult,
        'type'
      );

      this.groupedReactions = Object.keys(groupedResult).map((key: any) => [
        key,
        groupedResult[key],
      ]);

      this.reactionsList =
        PaginationHelper.newHandlePagination<SocialNetworkReaction>(
          this.reactionsList,
          'reactions',
          reactionsResult
        );
    } catch (error) {
      this._toastService.showError(error);
    }
  }

  public getAvatarUrl(avatarId: number): string {
    return CommonHelper.getAvatarImage(avatarId);
  }

  public goToParticipant(profileId: string): void {
    const routeString = Routes['participantsProfile'].fullPath.replace(
      ':profileId',
      profileId
    );
    this._commonService.navigate(routeString.split('/'));

    this.matDialogRef.close();
  }
}
